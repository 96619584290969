export const CountryEnum = {
  Vietnam: 192,
  Other: 0
}

export const CityEnum = {
  HaNoi: 24,
  TPHoChiMinh: 58,
  AnGiang: 1,
  BaRiaVungTau: 2,
  BacGiang: 3,
  BacKan: 4,
  BacLieu: 5,
  BacNinh: 6,
  BenTre: 7,
  BinhDinh: 8,
  BinhDuong: 9,
  BinhPhuoc: 10,
  BinhThuan: 11,
  CaMau: 12,
  CanTho: 13,
  CaoBang: 14,
  DaNang: 15,
  DakLak: 16,
  DakNong: 17,
  DienBien: 18,
  DongNai: 19,
  DongThap: 20,
  GiaLai: 21,
  HaGiang: 22,
  HaNam: 23,
  HaTinh: 25,
  HaiDuong: 26,
  HaiPhong: 27,
  HauGiang: 28,
  HoaBinh: 29,
  HungYen: 30,
  KhanhHoa: 31,
  KienGiang: 32,
  KonTum: 33,
  LaiChau: 34,
  LamDong: 35,
  LangSon: 36,
  LaoCai: 37,
  LongAn: 38,
  NamDinh: 39,
  NgheAn: 40,
  NinhBinh: 41,
  NinhThuan: 42,
  PhuTho: 43,
  PhuYen: 44,
  QuangBinh: 45,
  QuangNam: 46,
  QuangNgai: 47,
  QuangNinh: 48,
  QuangTri: 49,
  SocTrang: 50,
  SonLa: 51,
  TayNinh: 52,
  ThaiBinh: 53,
  ThaiNguyen: 54,
  ThanhHoa: 55,
  ThuaThienHue: 56,
  TienGiang: 57,
  TraVinh: 59,
  TuyenQuang: 60,
  VinhLong: 61,
  VinhPhuc: 62,
  YenBai: 63
}

export const RecurrenceOptionEnum = {
  NoRepeat: 0,
  Daily: 1,
  WeeklyOnDayOfWeek: 2,
  EveryWorkDaysOfWeek: 3,
  Custom: 4
}

export const RecurrenceFrequencyEnum = {
  DAILY: 3,
  WEEKLY: 2,
  MONTHLY: 1
}

export const RecurrenceEndsEnum = {
  Never: '0',
  OnDay: '1',
  After: '2'
}

export const EditEmployeeScheduleCaseEnum = {
  SingleToSingle: 1,
  SingleToRecurring: 2,
  RecurringInfoOnly: 3,
  RecurringInfoAndRrule: 4,
  RecurringInfoAndToSingle: 5
}

export const EditEmployeeScheduleApplyToEnum = {
  ThisOccurrence: 1,
  ThisAndFollowingOccurrences: 2
}

export const RecurrenceByWeekdayEnum = {
  MO: 0,
  TU: 1,
  WE: 2,
  TH: 3,
  FR: 4,
  SA: 5,
  SU: 6
}

export const RecurrenceByWeekdayDisplay = {
  [RecurrenceByWeekdayEnum.MO]: { title: 'MO' },
  [RecurrenceByWeekdayEnum.TU]: { title: 'TU' },
  [RecurrenceByWeekdayEnum.WE]: { title: 'WE' },
  [RecurrenceByWeekdayEnum.TH]: { title: 'TH' },
  [RecurrenceByWeekdayEnum.FR]: { title: 'FR' },
  [RecurrenceByWeekdayEnum.SA]: { title: 'SA' },
  [RecurrenceByWeekdayEnum.SU]: { title: 'SU' }
}

export const AppointmentStatusEnum = {
  NotApproved: 1000,
  Approved: 2000,
  CheckedIn: 3000,
  InProgress: 4000,
  DoneExamination: 4900,
  TodoBilling: 6100,
  InBilling: 6500,
  DoneBilling: 6900,
  Finished: 9000,
  Canceled: 9999,
  Invalid: 10000
}

export const AppointmentStatusDisplayConfig = {
  [AppointmentStatusEnum.NotApproved]: { title: 'NotApproved', color: 'light-secondary' },
  [AppointmentStatusEnum.Approved]: { title: 'Approved', color: 'light-primary' },
  [AppointmentStatusEnum.CheckedIn]: { title: 'CheckedIn', color: 'primary' },
  [AppointmentStatusEnum.InProgress]: { title: 'InProgress', color: 'success' },
  [AppointmentStatusEnum.DoneExamination]: { title: 'InProgress', color: 'success' },
  [AppointmentStatusEnum.TodoBilling]: { title: 'InProgress', color: 'success' },
  [AppointmentStatusEnum.InBilling]: { title: 'InProgress', color: 'success' },
  [AppointmentStatusEnum.DoneBilling]: { title: 'InProgress', color: 'success' },
  [AppointmentStatusEnum.Finished]: { title: 'Finished', color: 'light-info' },
  [AppointmentStatusEnum.Canceled]: { title: 'Canceled', color: 'light-dark' },
  [AppointmentStatusEnum.Invalid]: { title: 'Invalid', color: 'warning' }
}

export const ScheduleSlotAvailableDisplayConfig = {
  notApproved: { title: 'NotApproved', color: 'light-secondary' },
  available: { title: 'Available', color: 'success' },
  full: { title: 'Full', color: 'light-danger' }
}

export const GenderEnum = {
  Male: 0,
  Female: 1
}

export const GenderDisplay = {
  [GenderEnum.Male]: { title: 'Male' },
  [GenderEnum.Female]: { title: 'Female' }
}

export const PermissionTypeEnum = {
  None: 0,
  Read: 10,
  Write: 20
}

export const TabEnum = {
  Settings: 'settings',
  Dashboard: 'dashboard',
  Lists: 'lists',
  Reports: 'reports'
}

export const TitleTabEnum = {
  Settings: {
    id: 'title.clinicSettings',
    default: 'Clinic Settings'
  },
  Dashboard: {
    id: 'title.dashboard',
    default: 'Dashboard'
  },
  Lists: {
    id: 'title.lists',
    default: 'Lists'
  },
  Reports: {
    id: 'title.reports',
    default: 'Reports'
  }
}

export const FrontEndScreenEnum = {
  GeneralSettings: 1,
  Locations: 2,
  Specialties: 3,
  Employees: 4,
  Symptoms: 5,
  Users: 6,
  UserRoles: 7,
  UserGroups: 8,
  EmployeeSchedules: 9,
  Appointments: 10,
  TaskConsult: 11,
  Contacts: 12,
  Properties: 13,
  Forms: 14,
  Products: 15,
  MedicalRecords: 17,
  Services: 18,
  Packages: 19,
  WorkFlow: 20,
  BillingTask: 21,
  PurchasedPackages: 22,
  Invoices: 23,
  TaskService: 24,
  MemberClass: 25,
  MarketingCampaigns: 26,
  Suppliers: 27,
  StockOnHand: 28,
  StockSheets: 29,
  SalesSummaryReport: 30,
  SalesReportByProducts: 31,
  SalesReportByServices: 32,
  SalesReportByPackages: 33,
  SalesReportsByMarketingCampaigns: 34,
  DebtReport: 35,
  SalesReportByCustomer: 36,
  CostReports: 37,
  AppointmentSummaryReport: 38,
  AppointmentReportByLocations: 39,
  AppointmentReportByTypes: 40,
  MarketingCampaignReportByVoucher: 41,
  MemberClassesReport: 42,
  StockReportByProducts: 43,
  StockSheetsReport: 44,
  CustomerSummaryReport: 45,
  HrReportByAppointments: 46,
  Dashboard: 47
}

export const PermissionScreens = [
  {
    frontendScreenTitle: 'title.generalSettings',
    frontendScreenId: FrontEndScreenEnum.GeneralSettings,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.workFlow',
    frontendScreenId: FrontEndScreenEnum.WorkFlow,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.locations',
    frontendScreenId: FrontEndScreenEnum.Locations,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.users',
    frontendScreenId: FrontEndScreenEnum.Users,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.userRoles',
    frontendScreenId: FrontEndScreenEnum.UserRoles,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.userGroups',
    frontendScreenId: FrontEndScreenEnum.UserGroups,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.properties',
    frontendScreenId: FrontEndScreenEnum.Properties,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.forms',
    frontendScreenId: FrontEndScreenEnum.Forms,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.products',
    frontendScreenId: FrontEndScreenEnum.Products,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.suppliers',
    frontendScreenId: FrontEndScreenEnum.Suppliers,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.employeeSchedules',
    frontendScreenId: FrontEndScreenEnum.EmployeeSchedules,
    tab: TabEnum.Dashboard
  },
  {
    frontendScreenTitle: 'title.appointments',
    frontendScreenId: FrontEndScreenEnum.Appointments,
    tab: TabEnum.Dashboard
  },
  {
    frontendScreenTitle: 'title.taskConsult',
    frontendScreenId: FrontEndScreenEnum.TaskConsult,
    tab: TabEnum.Dashboard
  },
  {
    frontendScreenTitle: 'title.taskServicing',
    frontendScreenId: FrontEndScreenEnum.TaskService,
    tab: TabEnum.Dashboard
  },
  {
    frontendScreenTitle: 'title.billing',
    frontendScreenId: FrontEndScreenEnum.BillingTask,
    tab: TabEnum.Dashboard
  },
  {
    frontendScreenTitle: 'title.clientInfo',
    frontendScreenId: FrontEndScreenEnum.Contacts,
    tab: TabEnum.Lists
  },
  {
    frontendScreenTitle: 'title.invoices',
    frontendScreenId: FrontEndScreenEnum.Invoices,
    tab: TabEnum.Lists
  },
  {
    frontendScreenTitle: 'title.memberClass',
    frontendScreenId: FrontEndScreenEnum.MemberClass,
    tab: TabEnum.Settings
  },
  {
    frontendScreenTitle: 'title.marketingCampaigns',
    frontendScreenId: FrontEndScreenEnum.MarketingCampaigns,
    tab: TabEnum.Lists
  },
  {
    frontendScreenTitle: 'title.stockOnHand',
    frontendScreenId: FrontEndScreenEnum.StockOnHand,
    tab: TabEnum.Dashboard
  },
  {
    frontendScreenTitle: 'title.stockSheets',
    frontendScreenId: FrontEndScreenEnum.StockSheets,
    tab: TabEnum.Dashboard
  },
  {
    frontendScreenTitle: 'title.salesSummaryReport',
    frontendScreenId: FrontEndScreenEnum.SalesSummaryReport,
    tab: TabEnum.Reports
  },
  {
    frontendScreenTitle: 'title.salesReportByProducts',
    frontendScreenId: FrontEndScreenEnum.SalesReportByProducts,
    tab: TabEnum.Reports
  },
  {
    frontendScreenTitle: 'title.salesReportByServices',
    frontendScreenId: FrontEndScreenEnum.SalesReportByServices,
    tab: TabEnum.Reports
  },
  {
    frontendScreenTitle: 'title.saleReportByPackages',
    frontendScreenId: FrontEndScreenEnum.SalesReportByPackages,
    tab: TabEnum.Reports
  },
  {
    frontendScreenTitle: 'title.salesReportsByMarketingCampaigns',
    frontendScreenId: FrontEndScreenEnum.SalesReportsByMarketingCampaigns,
    tab: TabEnum.Reports
  },
  {
    frontendScreenTitle: 'title.salesDebtReports',
    frontendScreenId: FrontEndScreenEnum.DebtReport,
    tab: TabEnum.Reports
  },
  {
    frontendScreenTitle: 'title.salesReportByCustomer',
    frontendScreenId: FrontEndScreenEnum.SalesReportByCustomer,
    tab: TabEnum.Reports
  },
  {
    frontendScreenTitle: 'title.costSummaryReports',
    frontendScreenId: FrontEndScreenEnum.CostReports,
    tab: TabEnum.Reports
  },
  {
    frontendScreenTitle: 'title.appointmentSummaryReport',
    frontendScreenId: FrontEndScreenEnum.AppointmentSummaryReport,
    tab: TabEnum.Reports
  },
  {
    frontendScreenTitle: 'title.appointmentReportByLocations',
    frontendScreenId: FrontEndScreenEnum.AppointmentReportByLocations,
    tab: TabEnum.Reports
  },
  {
    frontendScreenTitle: 'title.appointmentReportByTypes',
    frontendScreenId: FrontEndScreenEnum.AppointmentReportByTypes,
    tab: TabEnum.Reports
  },
  {
    frontendScreenTitle: 'title.marketingCampaignReportByVoucher',
    frontendScreenId: FrontEndScreenEnum.MarketingCampaignReportByVoucher,
    tab: TabEnum.Reports
  },
  {
    frontendScreenTitle: 'title.memberClassesReport',
    frontendScreenId: FrontEndScreenEnum.MemberClassesReport,
    tab: TabEnum.Reports
  },
  {
    frontendScreenTitle: 'title.stockReportByProducts',
    frontendScreenId: FrontEndScreenEnum.StockReportByProducts,
    tab: TabEnum.Reports
  },
  {
    frontendScreenTitle: 'title.stockSheetsReport',
    frontendScreenId: FrontEndScreenEnum.StockSheetsReport,
    tab: TabEnum.Reports
  },
  {
    frontendScreenTitle: 'title.customerSummaryReport',
    frontendScreenId: FrontEndScreenEnum.CustomerSummaryReport,
    tab: TabEnum.Reports
  },
  {
    frontendScreenTitle: 'title.hrReportByAppointments',
    frontendScreenId: FrontEndScreenEnum.HrReportByAppointments,
    tab: TabEnum.Reports
  }
]

export const UserStatusEnum = {
  Inactive: 500,
  Active: 1000
}

export const UserStatusDisplayConfig = {
  [UserStatusEnum.Inactive]: { title: 'Inactive', color: 'light-warning' },
  [UserStatusEnum.Active]: { title: 'Active', color: 'light-success' }
}

export const SkillEnum = {
  Manager: 1,
  Receptionist: 2,
  Consultant: 3,
  Therapist: 4,
  Cashier: 5
}

export const SkillsDisplayConfig = [
  { skillLabel: 'label.manager', skillId: 1 },
  { skillLabel: 'label.receptionist', skillId: 2 },
  { skillLabel: 'label.consultant', skillId: 3 },
  { skillLabel: 'label.therapist', skillId: 4 },
  { skillLabel: 'label.cashier', skillId: 5 }
]

export const RoleTypeEnum = {
  Owner: 10,
  Admin: 20,
  UserDefined: 30
}

export const FeatureEnum = {
  101: { action: 'add', subject: 'appointments' },
  102: { action: 'approve', subject: 'appointments' },
  103: { action: 'checkIn', subject: 'appointments' },
  104: { action: 'examination', subject: 'appointments' },
  105: { action: 'cancel', subject: 'appointments' },
  106: { action: 'billing', subject: 'appointments' },
  107: { action: 'assignEmployee', subject: 'appointments' },
  108: { action: 'assignToMe', subject: 'appointments' },
  109: { action: 'doConsult', subject: 'appointments' },
  110: { action: 'doService', subject: 'appointments' }
}

export const ScreenEnum = {
  1: 'general',
  2: 'locations',
  3: 'specialties',
  4: 'employees',
  5: 'symptoms',
  6: 'users',
  7: 'roles',
  8: 'groups',
  9: 'employeeSchedules',
  10: 'appointments',
  11: 'taskConsulting',
  12: 'contacts',
  13: 'properties',
  14: 'forms',
  15: 'products',
  17: 'medicalRecords',
  18: 'services',
  19: 'packages',
  20: 'workFlow',
  21: 'billing',
  22: 'purchasedPackages',
  23: 'invoices',
  24: 'taskServicing',
  25: 'memberClass',
  26: 'marketingCampaigns',
  27: 'suppliers',
  28: 'stockOnHand',
  29: 'stockSheets',
  30: 'salesSummaryReport',
  31: 'salesReportByProducts',
  32: 'salesReportByServices',
  33: 'salesReportByPackages',
  34: 'salesReportByMarketingCampaigns',
  35: 'salesDebtReports',
  36: 'salesReportByCustomer',
  37: 'costSummaryReports',
  38: 'appointmentSummaryReport',
  39: 'appointmentReportByLocations',
  40: 'appointmentReportByTypes',
  41: 'marketingCampaignReportByVoucher',
  42: 'memberClassesReport',
  43: 'stockReportByProducts',
  44: 'stockSheetsReport',
  45: 'customerSummaryReport',
  46: 'hrReportByAppointments',
  47: 'dashboard'
}

export const KeyBoardEnum = {
  Escape: 'Escape',
  Tab: 'Tab',
  Enter: 'Enter'
}

export const FrontEndFeatureEnum = {
  AddAppointment: 101,
  ApproveAppointment: 102,
  CheckInAppointment: 103,
  Examination: 104,
  CancelAppointment: 105,
  Billing: 106,
  AssignEmployee: 107,
  AssignToMe: 108,
  DoConsult: 109,
  DoService: 110
}

export const AppointmentConfig = {
  AllowBookingBeforeMinutes: 0
}

export const PhysicalFileTypeEnum = {
  Avatar: 1,
  PrintTemplate: 2,
  PropertyImage: 3
}

export const PhysicalFileStatusEnum = {
  New: 0,
  OK: 1,
  WillBeDeleted: 2,
  Deleted: 3,
  Corrupted: 4
}

export const EntityTypeEnum = {
  ResultSheet: 3,
  CheckInSheet: 4,
  ServiceSheet: 5,
  ClinicPatient: 2
}

export const PropertyValueTypeEnum = {
  FreeText: 1,
  ListOfOptions: 2,
  Date: 3,
  DateTime: 4,
  Image: 5
}

export const MedicineUnitTypeEnum = {
  Tablet: 1,
  Blister: 2,
  Packet: 3,
  Sachet: 4,
  Tube: 5,
  Bottle: 6,
  Set: 7
}

export const DefaultValueOptionsEnum = {
  None: 0,
  Single: 1
  // Associate: 2
}

export const SignalREventEnum = {
  AddedAppointment: 1,
  AddedCheckInConsultingAppointment: 2,
  AddedCheckInServicingAppointment: 3,
  ApprovedAppointment: 4,
  CheckedInConsultingAppointment: 5,
  CheckedInServicingAppointment: 6,
  AssignConsultingAppointment: 7,
  AssignServicingAppointment: 8,
  StartedConsultingAppointment: 9,
  StartedServicingAppointment: 10,
  FinishedConsultingAppointment: 11,
  FinishedServicingAppointment: 12,
  CanceledAppointment: 13,
  CreatedInvoice: 14,
  ChangedInvoiceStatus: 15
  // InvalidedAppointment: 12
}

export const AppointmentsSignalREvents = [
  SignalREventEnum.AddedAppointment,
  SignalREventEnum.AddedCheckInConsultingAppointment,
  SignalREventEnum.AddedCheckInServicingAppointment,
  SignalREventEnum.ApprovedAppointment,
  SignalREventEnum.CheckedInConsultingAppointment,
  SignalREventEnum.CheckedInServicingAppointment,
  SignalREventEnum.AssignConsultingAppointment,
  SignalREventEnum.AssignServicingAppointment,
  SignalREventEnum.StartedConsultingAppointment,
  SignalREventEnum.StartedServicingAppointment,
  SignalREventEnum.FinishedConsultingAppointment,
  SignalREventEnum.FinishedServicingAppointment,
  SignalREventEnum.CanceledAppointment
]

export const TaskConsultingSignalREvents = [
  SignalREventEnum.AddedCheckInConsultingAppointment,
  SignalREventEnum.CheckedInConsultingAppointment,
  SignalREventEnum.AssignConsultingAppointment,
  SignalREventEnum.StartedConsultingAppointment,
  SignalREventEnum.FinishedConsultingAppointment,
  SignalREventEnum.CanceledAppointment
]

export const TaskServicingSignalREvents = [
  SignalREventEnum.AddedCheckInServicingAppointment,
  SignalREventEnum.CheckedInServicingAppointment,
  SignalREventEnum.AssignServicingAppointment,
  SignalREventEnum.StartedServicingAppointment,
  SignalREventEnum.FinishedServicingAppointment,
  SignalREventEnum.CanceledAppointment
]

export const BillingSignalREvents = [SignalREventEnum.CreatedInvoice, SignalREventEnum.ChangedInvoiceStatus]

export const WorkflowTypeEnum = {
  OneEmployeeWithPostBilling: 1
}

export const ReservedProperty = {
  [EntityTypeEnum.ResultSheet]: ['diagnosis', 'conclusion', 'next_appointment_date'],
  [EntityTypeEnum.CheckInSheet]: ['symptom'],
  [EntityTypeEnum.ServiceSheet]: ['diagnosis', 'conclusion', 'next_appointment_date'],
  [EntityTypeEnum.ClinicPatient]: []
}

export const AppointmentTypeEnum = {
  Consult: 1,
  Service: 2
}

export const AppointmentTypeDisplayConfig = {
  [AppointmentTypeEnum.Consult]: { title: 'Consult' },
  [AppointmentTypeEnum.Service]: { title: 'Service' }
}

export const InvoiceItemTypeEnum = {
  Product: 0,
  Service: 1,
  Package: 2,
  Other: 3
}

export const InvoicePaymentTypeEnum = {
  Cash: 1,
  BankTransfer: 2,
  EWallet: 3,
  Card: 4
}

export const InvoicePaymentTypeDisplayConfig = {
  [InvoicePaymentTypeEnum.Cash]: { title: 'Cash' },
  [InvoicePaymentTypeEnum.BankTransfer]: { title: 'BankTransfer' },
  [InvoicePaymentTypeEnum.EWallet]: { title: 'EWallet' },
  [InvoicePaymentTypeEnum.Card]: { title: 'Card' }
}

export const ProductTypeEnum = {
  Simple: 0,
  Service: 1,
  Package: 2
}

export const ProductTypeDisplayConfig = {
  [ProductTypeEnum.Simple]: { title: 'Simple' },
  [ProductTypeEnum.Service]: { title: 'Service' },
  [ProductTypeEnum.Package]: { title: 'Package' }
}

export const InvoiceStatusEnum = {
  New: 500,
  Draft: 1000,
  WaitForPayment: 5000,
  Unpaid: 8000,
  Paid: 9000,
  Canceled: 9999
}

export const InvoiceStatusDisplayConfig = {
  [InvoiceStatusEnum.New]: { title: 'New', color: 'success' },
  [InvoiceStatusEnum.Draft]: { title: 'Draft', color: 'secondary' },
  [InvoiceStatusEnum.WaitForPayment]: { title: 'WaitForPayment', color: 'warning' },
  [InvoiceStatusEnum.Unpaid]: { title: 'Unpaid', color: 'danger' },
  [InvoiceStatusEnum.Paid]: { title: 'Paid', color: 'success' },
  [InvoiceStatusEnum.Canceled]: { title: 'Canceled', color: 'secondary' }
}

export const MemberClassResetType = {
  DoNotReset: 0,
  Monthly: 1,
  Quarterly: 2,
  Yearly: 3
}

export const BirthdayConditionEnum = {
  DOBByDay: 0,
  DOBByWeek: 1,
  DOBByMonth: 2
}

export const BirthdayConditionDisplay = {
  [BirthdayConditionEnum.DOBByDay]: { title: 'DOBByDay' },
  [BirthdayConditionEnum.DOBByWeek]: { title: 'DOBByWeek' },
  [BirthdayConditionEnum.DOBByMonth]: { title: 'DOBByMonth' }
}

export const VoucherEntityTypeEnum = {
  MemberClass: 7,
  Location: 8,
  Product: 9
}

export const VoucherBenefitTypeEnum = {
  InvoiceDiscountAmount: 1,
  InvoiceDiscountPercent: 2,
  ProductDiscountAmount: 4,
  ProductDiscountPercent: 5
}

export const VoucherBenefitDiscountTypeEnum = {
  Percent: 1,
  Amount: 2
}

export const VoucherBenefitDiscountEntityTypeEnum = {
  Invoice: 1,
  Product: 2
}

export const CampaignStatusEnum = {
  NotStartedYet: 500,
  Enable: 1000,
  Disable: 2000,
  Finished: 9000
}

export const CampaignStatusDisplayConfig = {
  [CampaignStatusEnum.Enable]: { title: 'Enable', color: 'light-success' },
  [CampaignStatusEnum.NotStartedYet]: { title: 'NotStartedYet', color: 'light-warning' },
  [CampaignStatusEnum.Disable]: { title: 'Disable', color: 'light-secondary' },
  [CampaignStatusEnum.Finished]: { title: 'Finished', color: 'light-info' }
}

export const DiscountEntityTypeEnum = {
  PaidPackage: 1,
  Voucher: 2
}

export const VoucherConditionTypeEnum = {
  Gender: 1,
  Locations: 2,
  InvoiceAmountMinimum: 3,
  DaysOfWeek: 4,
  FromDateTime: 5,
  ToDateTime: 6,
  Birthday: 7,
  MemberClasses: 8
}

export const StockSheetTypeEnum = {
  StockImportSheet: 1,
  StockExportSheet: 2
}

export const StockSheetTypeDisplayConfig = {
  [StockSheetTypeEnum.StockImportSheet]: { title: 'StockImportSheet', color: 'light-success' },
  [StockSheetTypeEnum.StockExportSheet]: { title: 'StockExportSheet', color: 'light-danger' }
}

export const ChartFilterBy = {
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3
}
