import axios, { handleRefreshToken } from './base'

//#region AUTHENTICATION

export const loginAPI = async data => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, data, { __auth: false })
}

export const healthCheckAPI = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/health-check-patient`)
}

export const registerAPI = async (data, lang) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/register-clinic?lang=${lang}`, data, { __auth: false })
}

export const verifyEmailAPI = async data => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/verify-clinic-user`, data, { __auth: false })
}

export const resendConfirmationAPI = async (data, lang) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/resend-confirmation?lang=${lang}`, data, { __auth: false })
}

export const getUserInfoAPI = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/users/me`)
}

export const updateUserAPI = async data => {
  return axios.put(`${process.env.REACT_APP_API_URL}/users/me`, data)
}

export const updatePasswordAPI = async data => {
  return axios.put(`${process.env.REACT_APP_API_URL}/users/change-password`, data)
}

export const forgotPasswordAPI = async (data, lang) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot-password?lang=${lang}`, data)
}

export const resetPasswordAPI = async (data, lang) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/reset-password?lang=${lang}`, data)
}

export const refreshToken = async () => {
  return handleRefreshToken()
}

//#endregion

//#region GENERAL CLINIC SETTING

export const getClinicGeneralSettingsAPI = async (xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinics/general-settings`, { xScreenId, xFeatureId })
}

export const updateClinicGeneralSettingsAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinics/general-settings`, data, { xScreenId, xFeatureId })
}
//#endregion

//#region LOCATION

export const getLocationsAPI = async (pageSize, pageNumber, keyword = '', xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/locations?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${encodeURI(
      keyword
    )}`,
    { xScreenId, xFeatureId }
  )
}

export const createLocationsAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/locations`, data, { xScreenId, xFeatureId })
}

export const getLocationsIdAPI = async (locationId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/locations/${locationId}`, { xScreenId, xFeatureId })
}

export const updateLocationsAPI = async (locationId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/locations/${locationId}`, data, { xScreenId, xFeatureId })
}

export const deleteLocationsAPI = async (locationId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/locations/${locationId}`, { xScreenId, xFeatureId })
}

export const updateLocationsEnableAPI = async (locationId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/locations/${locationId}/set-enabled`, data, {
    xScreenId,
    xFeatureId
  })
}
//#endregion

//#region CLINIC INFO
export const getClinicInfoAPI = async (xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinics/info`, { xScreenId, xFeatureId })
}

export const createEmployeeScheduleAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/schedules`, data, { xScreenId, xFeatureId })
}

/**
 * Get schedule detail
 * dayily : "RRULE:FREQ=DAILY;INTERVAL=1"
 * WeeklyOnDayOfWeek:"RRULE:FREQ=WEEKLY;INTERVAL=1"
 * EveryWorkDaysOfWeek: 'RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TU,WE,TH,FR'
 * Custom: "RRULE:FREQ=MONTHLY;INTERVAL=2"
 */

export const getScheduleByIdAPI = async (scheduleId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/schedules/${scheduleId}`, { xScreenId, xFeatureId })
}

export const searchEmployeeScheduleAPI = async (filter, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/schedules/search`, filter, { xScreenId, xFeatureId })
}

export const updateEmployeeScheduleAPI = async (scheduleId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/schedules/${scheduleId}`, data, { xScreenId, xFeatureId })
}

export const deleteEmployeeScheduleAPI = async (scheduleId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/schedules/${scheduleId}/delete`, data, { xScreenId, xFeatureId })
}
export const getAppointmentByScheduleIdAPI = async (
  scheduleId,
  occurrenceStart,
  occurrenceEnd,
  xScreenId,
  xFeatureId
) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/schedules/${scheduleId}/get-appointments?occurrenceStart=${occurrenceStart}&occurrenceEnd=${occurrenceEnd}`,
    { xScreenId, xFeatureId }
  )
}
//#endregion

//#region CLINIC-PATIENT
export const getClinicPatientAPI = async (keyword, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-patients?keyword=${encodeURI(keyword)}`, {
    xScreenId,
    xFeatureId
  })
}

export const searchClinicPatientsAPI = async (
  pageSize,
  pageNumber,
  orderBy,
  orderByType,
  filter,
  xScreenId = 0,
  xFeatureId = 0
) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/clinic-patients/search?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&orderByType=${orderByType}`,
    filter,
    { xScreenId, xFeatureId }
  )
}
export const searchAllClinicPatientsAPI = async (
  filter,
  xScreenId = 0,
  xFeatureId = 0,
  pageNumber = 1,
  orderBy = '',
  orderByType = 0
) => {
  let arr = []
  const apiRes = await searchClinicPatientsAPI(100, pageNumber, orderBy, orderByType, filter, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await searchAllClinicPatientsAPI(filter, pageNumber + 1, xScreenId, xFeatureId)
      arr = [...arr, ...nextPageRes]
    }
  }
  return arr
}

export const deleteClinicPatientAPI = async (clinicPatientId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/clinic-patients/${clinicPatientId}`, { xScreenId, xFeatureId })
}

export const createClinicPatientAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/clinic-patients`, data, { xScreenId, xFeatureId })
}

export const getClinicPatientByIdAPI = async (clinicPatientId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-patients/${clinicPatientId}`, { xScreenId, xFeatureId })
}

export const updateClinicPatientAPI = async (clinicPatientId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-patients/${clinicPatientId}`, data, {
    xScreenId,
    xFeatureId
  })
}

export const searchMedicalRecordsAPI = async (
  pageSize,
  pageNumber,
  orderBy,
  orderByType,
  filter,
  xScreenId = 0,
  xFeatureId = 0
) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/clinic-patients/search-medical-records?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&orderByType=${orderByType}`,
    filter,
    { xScreenId, xFeatureId }
  )
}

export const searchAllMedicalRecordsAPI = async (
  filter,
  xScreenId = 0,
  xFeatureId = 0,
  pageNumber = 1,
  orderBy = '',
  orderByType = 0
) => {
  let arr = []
  const apiRes = await searchMedicalRecordsAPI(100, pageNumber, orderBy, orderByType, filter, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await searchAllMedicalRecordsAPI(filter, pageNumber + 1, xScreenId, xFeatureId)
      arr = [...arr, ...nextPageRes]
    }
  }
  return arr
}

export const getMedicalRecordAPI = async (clinicPatientId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-patients/${clinicPatientId}/medical`, {
    xScreenId,
    xFeatureId
  })
}

export const updateMedicalRecordAPI = async (clinicPatientId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-patients/${clinicPatientId}/medical`, data, {
    xScreenId,
    xFeatureId
  })
}

export const mergeClinicPatientsAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/clinic-patients/merge`, data, { xScreenId, xFeatureId })
}

// Get purchased packages for clinic patient
export const getPurchasedPackagesAPI = async (clinicPatientId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-patients/${clinicPatientId}/purchased-packages`, {
    xScreenId,
    xFeatureId
  })
}

// Get purchased packages details by ID
export const getPurchasedPackagesDetailsByIdAPI = async (clinicPatientId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/clinic-patients/${clinicPatientId}/purchased-package-details`,
    data,
    { xScreenId, xFeatureId }
  )
}

//#endregion

//#region CLINIC-APPOINTMENT
export const searchClinicAppointmentsAPI = async (pageSize, pageNumber, filter, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/clinic-appointments/search?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    { xScreenId, xFeatureId }
  )
}

export const searchAllClinicAppointmentsAPI = async (filter, xScreenId = 0, xFeatureId = 0, pageNumber = 1) => {
  let arr = []
  const apiRes = await searchClinicAppointmentsAPI(100, pageNumber, filter, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await searchAllClinicAppointmentsAPI(filter, pageNumber + 1, xScreenId, xFeatureId)
      arr = [...arr, ...nextPageRes]
    }
  }
  return arr
}

export const getResultSheetByAppointmentId = async (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/result-sheet`, {
    xScreenId,
    xFeatureId
  })
}

export const getAppointmentHistoryByPatientIdAPI = async (
  { clinicPatientId, pageSize = 20, pageNumber = 1 },
  xScreenId = 0,
  xFeatureId = 0
) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/get-by-patient-v2`, {
    xScreenId,
    xFeatureId,
    params: {
      clinicPatientId,
      pageSize,
      pageNumber
    }
  })
}

export const getPrescriptionDetailByAppointmentId = async (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/prescription`, {
    xScreenId,
    xFeatureId
  })
}

export const getScheduleSlotsAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/clinic-appointments/get-schedule-slots`, data, {
    xScreenId,
    xFeatureId
  })
}

export const getClinicAppointmentByIdAPI = (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}`, { xScreenId, xFeatureId })
}

export const getClinicAppointmentsHasInvalid = (xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/has-invalid`, { xScreenId, xFeatureId })
}

export const approveAppointmentAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-appointments/approve`, data, { xScreenId, xFeatureId })
}

export const checkInAppointmentAPI = async (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/check-in`, {
    xScreenId,
    xFeatureId
  })
}

export const cancelAppointmentAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-appointments/cancel`, data, { xScreenId, xFeatureId })
}

export const getNextAppointmentAPI = (employeeId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/next?employeeId=${employeeId}`, {
    xScreenId,
    xFeatureId
  })
}

export const getMedicalInfoAPI = (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/medical`, {
    xScreenId,
    xFeatureId
  })
}

export const getCountAppointmentsAPI = (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/clinic-appointments/search-count`, data, {
    xScreenId,
    xFeatureId
  })
}

export const updateSaleForAppointmentAPI = async (appointmentId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/sales`, data, {
    xScreenId,
    xFeatureId
  })
}

export const getSalesListByAppointmentIdAPI = async (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/sales`, {
    xScreenId,
    xFeatureId
  })
}

//#endregion

//#region CONSULT APPOINTMENT

export const createConsultAppointmentAPI = (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/clinic-appointments/consult`, data, { xScreenId, xFeatureId })
}

export const getConsultByIdForEditAPI = (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/consult/${appointmentId}/details-for-edit`, {
    xScreenId,
    xFeatureId
  })
}

export const updateClinicAppointmentConsultAPI = (appointmentId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-appointments/consult/${appointmentId}`, data, {
    xScreenId,
    xFeatureId
  })
}
//#endregion

//#region SERVICE APPOINTMENT

export const createServiceAppointmentAPI = (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/clinic-appointments/service`, data, { xScreenId, xFeatureId })
}

export const getServiceByIdForEditAPI = (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/service/${appointmentId}/details-for-edit`, {
    xScreenId,
    xFeatureId
  })
}

export const updateClinicAppointmentServiceAPI = (appointmentId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-appointments/service/${appointmentId}`, data, {
    xScreenId,
    xFeatureId
  })
}
//#endregion

//#region CLINIC QUEUE

export const changeAppointmentEmployeeAPI = async (appointmentId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/assign-employee`, data, {
    xScreenId,
    xFeatureId
  })
}

export const assignToMeAPI = async (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/assign-to-me`, {
    xScreenId,
    xFeatureId
  })
}

//#endregion

//#region CONSULT-TASK

export const startConsultingAPI = async (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/start-consulting`, {
    xScreenId,
    xFeatureId
  })
}

export const finishConsultingAPI = async (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/finish-consulting`, {
    xScreenId,
    xFeatureId
  })
}

export const getAllConsultByStatusAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/clinic-appointments/consult/all-by-status`, data, {
    xScreenId,
    xFeatureId
  })
}

export const getAllTodayConsultAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/clinic-appointments/consult/all-today`, data, {
    xScreenId,
    xFeatureId
  })
}
//#endregion

//#region SERVICE-TASK

export const startServicingAPI = async (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/start-servicing`, {
    xScreenId,
    xFeatureId
  })
}

export const finishServicingAPI = async (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/finish-servicing`, {
    xScreenId,
    xFeatureId
  })
}

export const getAllServiceByStatusAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/clinic-appointments/service/all-by-status`, data, {
    xScreenId,
    xFeatureId
  })
}

export const getAllTodayServiceAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/clinic-appointments/service/all-today`, data, {
    xScreenId,
    xFeatureId
  })
}
//#endregion

//#region NOTIFICATIONS

export const updateDeviceTokenAPI = data => {
  return axios.post(`${process.env.REACT_APP_API_URL}/users/register-device`, data)
}

export const markANotificationAsReadAPI = notificationId => {
  return axios.put(`${process.env.REACT_APP_API_URL}/notifications/${notificationId}/read`)
}

export const markAllNotificationAsReadAPI = () => {
  return axios.put(`${process.env.REACT_APP_API_URL}/notifications/read-all`)
}

export const getNotificationsAPI = (pageSize, pageNumber, lang) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/notifications?lang=${lang}&pageSize=${pageSize}&pageNumber=${pageNumber}`
  )
}

export const deleteNotificationsAPI = notificationId => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/notifications/${notificationId}`)
}

export const getCountOfUnreadNotiAPI = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/notifications/unread-count`)
}
//#endregion

//#region ROLES

export const getRolesAPI = (pageSize, pageNumber, keyword = '', xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/roles?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${encodeURI(
      keyword
    )}`,
    { xScreenId, xFeatureId }
  )
}

export const createRolesAPI = (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/roles`, data, { xScreenId, xFeatureId })
}

export const updateRolesAPI = (roleId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/roles/${roleId}`, data, { xScreenId, xFeatureId })
}

export const deleteRolesAPI = (roleId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/roles/${roleId}`, { xScreenId, xFeatureId })
}

export const getRolesDetailAPI = (roleId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/roles/${roleId}`, { xScreenId, xFeatureId })
}

export const updateRolesEnableAPI = (roleId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/roles/${roleId}/set-enabled`, data, { xScreenId, xFeatureId })
}
//#endregion

//#region USER ACCOUNTS API

export const getUserAccountsAPI = async (pageSize, pageNumber, keyword = '', xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/employees?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${encodeURI(
      keyword
    )}`,
    { xScreenId, xFeatureId }
  )
}

export const getAllUserAccountsAPI = async (keyword, xScreenId = 0, xFeatureId = 0, pageNumber = 1) => {
  const arr = []
  const apiRes = await getUserAccountsAPI(100, pageNumber, keyword, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > apiRes.data.paging.pageSize + (apiRes.data.paging.pageNumber - 1) * 99) {
      const nextPageRes = await getAllUserAccountsAPI(keyword, xScreenId, xFeatureId, pageNumber + 1)
      arr.concat(nextPageRes)
    }
  }
  return arr
}

export const getUserAccountByIdAPI = async (employeeId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/employees/${employeeId}`, { xScreenId, xFeatureId })
}

export const inviteUserAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/employees/invite`, data, { xScreenId, xFeatureId })
}

export const updateUserAccountAPI = async (employeeId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/employees/${employeeId}`, data, { xScreenId, xFeatureId })
}

export const deleteUserAccountAPI = async (employeeId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/employees/${employeeId}`, { xScreenId, xFeatureId })
}

export const updateUserEnableAPI = async (employeeId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/employees/${employeeId}/set-enabled`, data, {
    xScreenId,
    xFeatureId
  })
}

export const resetUserPasswordAPI = async (employeeId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/employees/${employeeId}/change-password`, data, {
    xScreenId,
    xFeatureId
  })
}

export const getUserPermissionAPI = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/employees/my-permissions`)
}

export const reinviteUserAPI = async (employeeId, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/employees/${employeeId}/re-invite`, {
    xScreenId,
    xFeatureId
  })
}

export const changeClinicOwnerAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/clinics/change-owner`, data, {
    xScreenId,
    xFeatureId
  })
}
//#endregion

//#region GROUPS

export const getUserGroupsAPI = async (pageSize, pageNumber, keyword = '', xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/user-groups?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${encodeURI(
      keyword
    )}`,
    { xScreenId, xFeatureId }
  )
}

export const createUserGroupAPI = (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/user-groups`, data, { xScreenId, xFeatureId })
}

export const updateUserGroupAPI = (userGroupId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/user-groups/${userGroupId}`, data, { xScreenId, xFeatureId })
}

export const deleteUserGroupAPI = (userGroupId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/user-groups/${userGroupId}`, { xScreenId, xFeatureId })
}

export const updateUserGroupEnableAPI = (userGroupId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/user-groups/${userGroupId}/set-enabled`, data, {
    xScreenId,
    xFeatureId
  })
}

export const getUserGroupDetailAPI = (userGroupId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/user-groups/${userGroupId}`, { xScreenId, xFeatureId })
}
//#endregion

//#region UPLOAD IMAGE

export const createPhysicalFileAPI = async data => {
  return axios.post(`${process.env.REACT_APP_API_URL}/files/request-upload-image`, data)
}

export const putUploadImageAPI = async (preSignedURL, data) => {
  return axios.put(`${preSignedURL}`, data, {
    __auth: false,
    upload: data.type
  })
}

export const putUploadDoneAPI = async fileId => {
  return axios.put(`${process.env.REACT_APP_API_URL}/files/${fileId}/upload-done`)
}

export const getFileUrlsAPI = async data => {
  return axios.post(`${process.env.REACT_APP_API_URL}/files/get-urls`, data)
}

//#endregion

//#region PROPERTIES

export const getPropertiesAPI = async (pageSize, pageNumber, keyword, entityTypeId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${
      process.env.REACT_APP_API_URL
    }/properties/search?pageSize=${pageSize}&pageNumber=${pageNumber}&entityTypeId=${entityTypeId}&keyword=${encodeURI(
      keyword
    )}`,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const getAllPropertiesAPI = async (entityTypeId, keyword, xScreenId = 0, xFeatureId = 0, pageNumber = 1) => {
  const arr = []
  const apiRes = await getPropertiesAPI(100, pageNumber, keyword, entityTypeId, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > apiRes.data.paging.pageSize + (apiRes.data.paging.pageNumber - 1) * 99) {
      const nexPageRes = await getPropertiesAPI(entityTypeId, keyword, xScreenId, xFeatureId, pageNumber + 1)
      arr.concat(nexPageRes)
    }
  }
  return arr
}

export const createPropertyAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/properties`, data, { xScreenId, xFeatureId })
}

export const updatePropertyAPI = async (propertyId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/properties/${propertyId}`, data, { xScreenId, xFeatureId })
}

export const getPropertyByIdAPI = async (propertyId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/properties/${propertyId}`, { xScreenId, xFeatureId })
}

export const deletePropertyAPI = async (propertyId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/properties/${propertyId}`, { xScreenId, xFeatureId })
}
//#endregion

//#region FORMS

export const getFormsAPI = async (pageSize, pageNumber, entityTypeId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/forms?pageSize=${pageSize}&pageNumber=${pageNumber}&entityTypeId=${entityTypeId}`,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const createFormAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/forms`, data, { xScreenId, xFeatureId })
}

export const updateFormAPI = async (formId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/forms/${formId}`, data, { xScreenId, xFeatureId })
}

export const getFormByIdAPI = async (formId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/forms/${formId}`, { xScreenId, xFeatureId })
}

export const deleteFormAPI = async (formId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/forms/${formId}`, { xScreenId, xFeatureId })
}

export const updateEnableFormAPI = async (formId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/forms/${formId}/set-enabled`, data, { xScreenId, xFeatureId })
}

export const getAllFormsByEntityTypeAPI = async (entityTypeId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/forms/all-by-entity-type?entityTypeId=${entityTypeId}`, {
    xScreenId,
    xFeatureId
  })
}

export const getFormPropertiesAPI = async (formId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/forms/${formId}/properties`, { xScreenId, xFeatureId })
}

//! DEPRECATED
export const getPrintTemplatesAPI = async (xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinics/print-templates`, { xScreenId, xFeatureId })
}
// #endregion

//#region PRODUCTS
export const getProductsAPI = async (
  pageSize,
  pageNumber,
  productTypeId,
  keyword = '',
  xScreenId = 0,
  xFeatureId = 0
) => {
  return axios.get(
    `${
      process.env.REACT_APP_API_URL
    }/products?pageSize=${pageSize}&pageNumber=${pageNumber}&productTypeId=${productTypeId}&keyword=${encodeURI(
      keyword
    )}`,
    { xScreenId, xFeatureId }
  )
}

export const getProductDetailsByIdAPI = async (productId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/products/${productId}`, { xScreenId, xFeatureId })
}

export const getAllProductsAPI = async (productTypeId, keyword, xScreenId = 0, xFeatureId = 0, pageNumber = 1) => {
  const arr = []
  const apiRes = await getProductsAPI(100, pageNumber, productTypeId, keyword, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > apiRes.data.paging.pageSize + (apiRes.data.paging.pageNumber - 1) * 99) {
      const nexPageRes = await getAllProductsAPI(productTypeId, keyword, xScreenId, xFeatureId, pageNumber + 1)
      arr.concat(nexPageRes)
    }
  }
  return arr
}

export const createProductAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/products`, data, { xScreenId, xFeatureId })
}

export const deleteProductAPI = async (productId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/products/${productId}`, { xScreenId, xFeatureId })
}

export const updateProductAPI = async (productId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/products/${productId}`, data, { xScreenId, xFeatureId })
}
export const updateProductEnableAPI = async (productId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/products/${productId}/set-enabled`, data, {
    xScreenId,
    xFeatureId
  })
}

//#endregion

//#region RESULT SHEET

export const createResultSheetAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/result-sheets`, data, { xScreenId, xFeatureId })
}
//#endregion

//#region PACKAGES

export const searchPackagesAPI = async (pageSize, pageNumber, filter, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/packages/search?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    { xScreenId, xFeatureId }
  )
}

export const getAllPackagesAPI = async (filter = {}, xScreenId = 0, xFeatureId = 0, pageNumber = 1) => {
  const arr = []
  const apiRes = await searchPackagesAPI(100, pageNumber, filter, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > apiRes.data.paging.pageSize + (apiRes.data.paging.pageNumber - 1) * 99) {
      const nexPageRes = await getAllPackagesAPI(keyword, xScreenId, xFeatureId, pageNumber + 1)
      arr.concat(nexPageRes)
    }
  }
  return arr
}

export const createPackageAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/packages`, data, { xScreenId, xFeatureId })
}

export const updatePackageAPI = async (productId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/packages/${productId}`, data, { xScreenId, xFeatureId })
}

export const getPackageDetailsAPI = async (productId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/packages/${productId}`, { xScreenId, xFeatureId })
}

export const setEnabledPackageAPI = async (packageId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/packages/${packageId}/set-enabled`, data, {
    xScreenId,
    xFeatureId
  })
}

export const deletePackageAPI = async (packageId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/packages/${packageId}`, { xScreenId, xFeatureId })
}

//#endregion

//#region SERVICES

export const getServicesAPI = async (pageSize, pageNumber, keyword = '', xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/services?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${encodeURI(
      keyword
    )}`,
    { xScreenId, xFeatureId }
  )
}

export const searchAllServicesAPI = async (keyword = '', pageNumber = 1) => {
  let arr = []
  const apiRes = await getServicesAPI(100, pageNumber, keyword)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await getServicesAPI(keyword, pageNumber + 1)
      arr = [...arr, ...nextPageRes]
    }
  }
  return arr
}

export const createServiceAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/services`, data, { xScreenId, xFeatureId })
}

export const updateServiceAPI = async (productId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/services/${productId}`, data, { xScreenId, xFeatureId })
}

export const getServiceByIdAPI = async (productId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/services/${productId}`, { xScreenId, xFeatureId })
}

export const updateServicesEnableAPI = async (serviceId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/services/${serviceId}/set-enabled`, data, {
    xScreenId,
    xFeatureId
  })
}

export const getServicesOfAppointmentAPI = async (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/services`, {
    xScreenId,
    xFeatureId
  })
}

export const updateServicesOfAppointmentAPI = async (appointmentId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/services`, data, {
    xScreenId,
    xFeatureId
  })
}
// #endregion

//#region CHECK-IN FORM

export const createCheckInAppointmentAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/check-in-sheets`, data, { xScreenId, xFeatureId })
}

export const getCheckInSheetAPI = async (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/check-in-sheet`, {
    xScreenId,
    xFeatureId
  })
}

//#endregion

//#region SERVICE SHEET

export const createServiceSheetsAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/service-sheets`, data, { xScreenId, xFeatureId })
}

export const getServiceSheetsAPI = async (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/service-sheets`, {
    xScreenId,
    xFeatureId
  })
}
// #endregion

//#region PURCHASED PACKAGE

export const searchPurchasedPackagesAPI = async (pageSize, pageNumber, filter, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/orders/packages/search?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    { xScreenId, xFeatureId }
  )
}

export const searchAllPurchasedPackagesAPI = async (filter, xScreenId = 0, xFeatureId = 0, pageNumber = 1) => {
  let arr = []
  const apiRes = await searchPurchasedPackagesAPI(100, pageNumber, filter, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await searchPurchasedPackagesAPI(filter, pageNumber + 1, xScreenId, xFeatureId)
      arr = [...arr, ...nextPageRes]
    }
  }
  return arr
}

export const getPurchasedPackageDetail = async (packageId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/orders/packages/${packageId}/details`, {
    xScreenId,
    xFeatureId
  })
}

export const getPurchasedPackageHistoryByPurchasedPackageId = async (packageId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/orders/packages/${packageId}/usage-history`, {
    xScreenId,
    xFeatureId
  })
}

// #endregion

//#region WORK FLOW

export const getWorkFlowAPI = async (xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinics/workflow`, { xScreenId, xFeatureId })
}

export const updateWorkFlowAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinics/workflow`, data, { xScreenId, xFeatureId })
}

// #endregion

//#region INVOICES

export const createInvoiceAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/invoices`, data, { xScreenId, xFeatureId })
}

export const searchInvoicesAPI = async (pageSize, pageNumber, filter, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/invoices/search?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    { xScreenId, xFeatureId }
  )
}

export const searchAllInvoicesAPI = async (filter, xScreenId = 0, xFeatureId = 0, pageNumber = 1) => {
  let arr = []
  const apiRes = await searchInvoicesAPI(100, pageNumber, filter, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await searchPurchasedPackagesAPI(filter, pageNumber + 1, xScreenId, xFeatureId)
      arr = [...arr, ...nextPageRes]
    }
  }
  return arr
}

export const getInvoiceDetailAPI = async (invoiceId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/invoices/${invoiceId}`, {
    xScreenId,
    xFeatureId
  })
}

export const startInvoiceAPI = async (invoiceId, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/start`, { xScreenId, xFeatureId })
}

export const getAllTodayInvoicesAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/invoices/all-today`, data, {
    xScreenId,
    xFeatureId
  })
}

export const getAllInvoicesByStatusAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/invoices/all-by-status`, data, {
    xScreenId,
    xFeatureId
  })
}

export const cancelInvoiceAPI = async (invoiceId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/cancel`, data, { xScreenId, xFeatureId })
}

export const saveInvoiceItemsAPI = async (invoiceId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/save-items`, data, { xScreenId, xFeatureId })
}

export const reviseInvoiceAPI = async (invoiceId, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/revise`, undefined, {
    xScreenId,
    xFeatureId
  })
}

export const unpaidInvoiceAPI = async (invoiceId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/unpaid`, data, { xScreenId, xFeatureId })
}

export const payInvoiceAPI = async (invoiceId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/pay`, data, { xScreenId, xFeatureId })
}

export const getPurchasedProductsAPI = async (
  clinicPatientId,
  pageSize,
  pageNumber,
  data,
  xScreenId = 0,
  xFeatureId = 0
) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/clinic-patients/${clinicPatientId}/purchased-products?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    data,
    {
      xScreenId,
      xFeatureId
    }
  )
}
// #endregion

//#region MEMBER CLASS
export const getMemberClassSettingsAPI = async (xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinics/member-class-settings`, { xScreenId, xFeatureId })
}

export const updateMemberClassSettingsAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinics/member-class-settings`, data, { xScreenId, xFeatureId })
}
// #endregion

//#region MARKETING CAMPAIGNS
export const createMarketingCampaignAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/marketing-campaigns`, data, { xScreenId, xFeatureId })
}

export const getDetailsMarketingCampaignAPI = async (marketingCampaignId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/marketing-campaigns/${marketingCampaignId}`, {
    xScreenId,
    xFeatureId
  })
}

export const updateMarketingCampaignAPI = async (marketingCampaignId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/marketing-campaigns/${marketingCampaignId}`, data, {
    xScreenId,
    xFeatureId
  })
}

export const searchMarketingCampaignsAPI = async (
  campaignStatusId = null,
  keyword = '',
  pageSize,
  pageNumber,
  xScreenId = 0,
  xFeatureId = 0
) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/marketing-campaigns/search?campaignStatusId=${campaignStatusId}&pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${keyword}`,
    { xScreenId, xFeatureId }
  )
}

export const getVouchersOfCampaignAPI = async (marketingCampaignId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/marketing-campaigns/${marketingCampaignId}/vouchers`, {
    xScreenId,
    xFeatureId
  })
}

export const createVoucherAPI = async (marketingCampaignId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/marketing-campaigns/${marketingCampaignId}/vouchers`, data, {
    xScreenId,
    xFeatureId
  })
}

export const deleteMarketingCampaignAPI = async (marketingCampaignId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/marketing-campaigns/${marketingCampaignId}`, {
    xScreenId,
    xFeatureId
  })
}

export const updateMarketingCampaignEnableAPI = async (marketingCampaignId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/marketing-campaigns/${marketingCampaignId}/set-enabled`, data, {
    xScreenId,
    xFeatureId
  })
}

export const getVoucherDetailsAPI = async (marketingCampaignId, voucherId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/marketing-campaigns/${marketingCampaignId}/vouchers/${voucherId}`,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const deleteVoucherAPI = async (marketingCampaignId, voucherId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(
    `${process.env.REACT_APP_API_URL}/marketing-campaigns/${marketingCampaignId}/vouchers/${voucherId}`,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const updateVoucherAPI = async (marketingCampaignId, voucherId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(
    `${process.env.REACT_APP_API_URL}/marketing-campaigns/${marketingCampaignId}/vouchers/${voucherId}`,
    data,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const getAvailabelVouchersAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/marketing-campaigns/available-vouchers`, data, {
    xScreenId,
    xFeatureId
  })
}
// #endregion

//#region SUPPLIERS

export const createSupplierAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/suppliers`, data, {
    xScreenId,
    xFeatureId
  })
}

export const getDetailsSupplierAPI = async (supplierId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/suppliers/${supplierId}`, {
    xScreenId,
    xFeatureId
  })
}

export const updateSupplierAPI = async (supplierId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/suppliers/${supplierId}`, data, {
    xScreenId,
    xFeatureId
  })
}

export const searchSuppliersAPI = async (pageSize, pageNumber, keyword = '', xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${
      process.env.REACT_APP_API_URL
    }/suppliers/search?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${encodeURI(keyword)}`,
    { xScreenId, xFeatureId }
  )
}

export const deleteSupplierAPI = async (supplierId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/suppliers/${supplierId}`, {
    xScreenId,
    xFeatureId
  })
}

export const setEnableSupplierAPI = async (supplierId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/suppliers/${supplierId}/set-enabled`, data, {
    xScreenId,
    xFeatureId
  })
}
export const searchAllSuppliersAPI = async (keyword, pageNumber = 1, xScreenId = 0, xFeatureId = 0) => {
  let arr = []
  const apiRes = await searchSuppliersAPI(100, pageNumber, keyword, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await searchAllSuppliersAPI(keyword, pageNumber + 1, xScreenId, xFeatureId)
      arr = [...arr, ...nextPageRes]
    }
  }
  return arr
}

// #endregion

// #region STOCK ON HAND

export const searchStockOnHandAPI = async (pageSize, pageNumber, keyword = '', xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/stock-sheets/stock-on-hand?keyword=${keyword}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const getStockHistoryByProductIdAPI = async (productId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/stock-sheets/${productId}/history`, {
    xScreenId,
    xFeatureId
  })
}

// #endregion

// #region STOCK SHEET

export const createStockSheetAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/stock-sheets`, data, {
    xScreenId,
    xFeatureId
  })
}

export const getStockSheetDetailsAPI = async (stockSheetId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/stock-sheets/${stockSheetId}`, {
    xScreenId,
    xFeatureId
  })
}

export const searchStockSheetsAPI = async (pageSize, pageNumber, filter, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/stock-sheets/search?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    { xScreenId, xFeatureId }
  )
}

// #endregion

// #region report

export const searchAppointmentReportByLocationsAPI = async (
  pageSize,
  pageNumber,
  filter,
  xScreenId = 0,
  xFeatureId = 0
) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/reports/appointment-by-locations?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    { xScreenId, xFeatureId }
  )
}

export const searchAppointmentReportByTypesAPI = async (filter, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/reports/appointment-by-types?`, filter, { xScreenId, xFeatureId })
}

export const searchAppointmentSummaryReportAPI = async (
  pageSize,
  pageNumber,
  filter,
  xScreenId = 0,
  xFeatureId = 0
) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/reports/appointment-summary?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    { xScreenId, xFeatureId }
  )
}

export const searchStockReportByProductsAPI = async (
  keyword = '',
  pageSize,
  pageNumber,
  filter,
  xScreenId = 0,
  xFeatureId = 0
) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/reports/stock-by-products?keyword=${keyword}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const searchStockSheetsReportAPI = async (pageSize, pageNumber, filter, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/reports/stock-sheets?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    { xScreenId, xFeatureId }
  )
}

export const searchHrReportByAppointmentsAPI = async (
  keyword = '',
  pageSize,
  pageNumber,
  filter,
  xScreenId = 0,
  xFeatureId = 0
) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/reports/hr-by-appointment?keyword=${keyword}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const searchMemberClassesReportAPI = async (xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/reports/member-classes?`, { xScreenId, xFeatureId })
}

export const searchCustomerSummaryReportAPI = async (
  keyword = '',
  pageSize,
  pageNumber,
  filter,
  xScreenId = 0,
  xFeatureId = 0
) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/reports/customer-summary?keyword=${keyword}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const searchSalesDebtReportsAPI = async (
  keyword = '',
  pageSize,
  pageNumber,
  filter,
  xScreenId = 0,
  xFeatureId = 0
) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/reports/debts?keyword=${keyword}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const searchSaleReportsByCustomerAPI = async (
  keyword = '',
  pageSize,
  pageNumber,
  filter,
  xScreenId = 0,
  xFeatureId = 0
) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/reports/sales-by-customer?keyword=${keyword}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const getSaleCostReportsAPI = async (filter, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/reports/cost-summary?fromDateTimeUnix=${filter.fromDate}&toDateTimeUnix=${filter.toDate}`,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const searchSaleReportsByProductAPI = async (
  keyword = '',
  pageSize,
  pageNumber,
  filter,
  xScreenId = 0,
  xFeatureId = 0
) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/reports/sales-by-product?keyword=${keyword}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    {
      xScreenId,
      xFeatureId
    }
  )
}
export const searchSaleReportsByMarketingCampaignsAPI = async (
  keyword = '',
  pageSize,
  pageNumber,
  filter,
  xScreenId = 0,
  xFeatureId = 0
) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/reports/sales-by-marketing-campaign?keyword=${keyword}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const searchMarketingCampaignsReportByVoucherAPI = async (
  pageSize,
  pageNumber,
  filter,
  xScreenId = 0,
  xFeatureId = 0
) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/reports/marketing-campaign-by-vouchers?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const searchSalesSummaryReportAPI = async (filter, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/reports/sales-summary?fromDatimeTimeUnix=${filter.fromDateTimeUnix}&toDatimeTimeUnix=${filter.toDateTimeUnix}`,
    {
      xScreenId,
      xFeatureId
    }
  )
}
// #endregion report

// #region dashboard
export const getTotalStatisticAPI = async (filter, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/dashboard/total-statistic?fromDateTimeUnix=${filter.fromDate}&toDateTimeUnix=${filter.toDate}`,
    { xScreenId, xFeatureId }
  )
}

export const searchDashboardAppointmentSummaryAPI = async (filter, xScreenId, xFeatureId) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/dashboard/appointment-summary?fromDateTimeUnix=${filter.fromDate}&toDateTimeUnix=${filter.toDate}`,
    { xScreenId, xFeatureId }
  )
}

export const searchDashboardCostSummaryAPI = async (filter, xScreenId, xFeatureId) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/dashboard/cost-summary?fromDateTimeUnix=${filter.fromDate}&toDateTimeUnix=${filter.toDate}`,
    { xScreenId, xFeatureId }
  )
}

export const searchDashboardSalesAPI = async (filter, xScreenId, xFeatureId) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/dashboard/total-gross-sale?fromDateTimeUnix=${filter.fromDate}&toDateTimeUnix=${filter.toDate}`,
    { xScreenId, xFeatureId }
  )
}

// #endregion dashboard
